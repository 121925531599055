/* Nav */
.nav {
	padding: 20px 0;
	background-color: var(--nav-bg);
	border-bottom: 1px solid var(--nav-border);
	color: var(--nav-text);
	letter-spacing: normal;
}

.nav-row {
	display: flex;
	justify-content: flex-end;
	align-items: center;
	column-gap: 30px;
	row-gap: 20px;
	flex-wrap: wrap;
}

.logo {
	margin-right: auto;
	color: var(--nav-text);
	font-size: 24px;
	font-family: 'Poppins', sans-serif;
}

.logo strong {
	font-weight: 700;
}

.nav-list {
	display: flex;
	flex-wrap: wrap;
	row-gap: 10px;
	align-items: center;
	column-gap: 40px;
	font-size: 16px;
	font-weight: 500;
	font-family: 'Poppins', sans-serif;
	transition: max-height 0.3s ease, opacity 0.3s ease;
}

/* Link Styles */
.nav-list__link {
	color: var(--nav-text);
	transition: opacity 0.2s ease-in;
}

.nav-list__link:hover {
	opacity: 0.8;
}

.nav-list__link--active {
	position: relative;
}

.nav-list__link--active::before {
	content: '';
	position: absolute;
	left: 0;
	top: 100%;
	display: block;
	height: 2px;
	width: 100%;
	background-color: var(--accent);
}

/* Hamburger menu for mobile */
.menu-icon {
	display: none;
	cursor: pointer;
	flex-direction: column;
	gap: 5px;
	height: 24px;
}

.menu-icon__bar {
	width: 25px;
	height: 3px;
	background-color: var(--nav-text);
	transition: transform 0.3s ease, opacity 0.3s ease;
}

/* Change hamburger icon when menu is open */
.menu-icon__bar.open:nth-child(1) {
	transform: rotate(45deg) translate(5px, 5px);
}

.menu-icon__bar.open:nth-child(2) {
	opacity: 0;
}

.menu-icon__bar.open:nth-child(3) {
	transform: rotate(-45deg) translate(5px, -5px);
}

/* Responsive styles */
@media (max-width: 768px) {
	/* Hide nav-list by default on mobile */
	.nav-list {
		display: block;
		position: absolute;
		top: 60px;
		right: 0;
		background-color: var(--nav-bg);
		width: 100%;
		flex-direction: column;
		text-align: center;
		padding: 20px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
		opacity: 0;
		max-height: 0;
		overflow: hidden;
		transition: max-height 0.3s ease, opacity 0.3s ease;
	}

	/* Show the nav-list when open */
	.nav-list--open {
		opacity: 1;
		max-height: 400px; /* Enough to show all links */
	}

	/* Display hamburger menu icon */
	.menu-icon {
		display: flex;
	}

	.nav-list__item {
		margin-bottom: 15px;
	}
}
