#globe {
    stroke-width: 0.5;
    stroke: var(--page-bg);
  }
  
  path {
    cursor: grab;
  }
  
  path:active {
    cursor: grabbing;
  }
  
  .water {
    /* fill: white; */
    fill: var(--page-bg);
  }
  
  .land {
    /* fill: black; */
    fill: var(--text-color);
  }
  
  .visited {
    fill: #3490dc; /* Primary color */
    cursor: pointer;
  }
  
  .visited:hover {
    fill: #6574cd; /* Secondary color */
  }
  