@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Playfair+Display:wght@400;700&display=swap');

body.zarucha-background {
    background-color: #e5e2d8;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
}

.embla {
    overflow: hidden;
    width: 100%;
    max-width: 43.75rem; /* Limit carousel width */
    height: 31.25rem; /* Set a fixed height for the carousel */
    margin: auto; /* Center horizontally */
}

.embla__container {
    display: flex;
    height: 100%; /* Ensure the slides take full height */
}

.embla__slide {
    flex: 0 0 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e5e2d8;
    position: relative;
}

.embla__image {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.headline {
    font-family: 'Dancing Script', 'Playfair Display', cursive;
    font-size: 2.5rem;
    color: #814c20;
    text-align: center;
    margin: 0.5rem 0;
    letter-spacing: 0.05em; /* Subtle spacing for elegance */
}


.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    max-width: 500px;
    width: 90%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(4px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-container {
    background-color: #fff5f5;
    width: 90%;
    max-width: 32rem;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.letter-content {
    background-color: white;
    padding: 2rem;
    border: 2px solid #fecdd3;
    border-radius: 0.375rem;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}

.letter-content h1 {
    color: #e11d48;
    font-size: 1.875rem;
    font-family: serif;
    margin-bottom: 1.5rem;
    text-align: center;
}

.letter-body {
    font-family: serif;
    color: #1f2937;
    line-height: 1.75;
}

.letter-body p {
    margin-bottom: 1rem;
}

.opening::first-letter {
    font-size: 2rem;
    font-weight: bold;
    color: #e11d48;
}

.highlight {
    color: #e11d48;
    font-size: 1.125rem;
    font-weight: 500;
}

.closing {
    font-style: italic;
}

.letter-button button {
    display: block;
    margin: 2rem auto 0;
    padding: 0.5rem 1.5rem;
    background-color: #e11d48;
    color: white;
    border: none;
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.2s, box-shadow 0.2s;
}

.letter-button button:hover {
    background-color: #be123c;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.password-input {
    width: 80%;
    padding: 0.5rem;
    margin: 1rem 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.modal-content button {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #a0522d;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Dancing Script', cursive;
}


.modal-content button:hover {
    background-color: #814c20;
}

.carousel-button {
    font-family: 'Dancing Script', cursive; /* Use the same font as headlines */
    font-size: 1.5rem; /* Size the button text appropriately */
    color: #fff; /* White text color */
    background-color: #a0522d; /* Warm, romantic color */
    padding: 1rem 2rem; /* Add padding for a more elegant feel */
    border: none;
    border-radius: 25px; /* Rounded corners for an elegant look */
    cursor: pointer;
    display: inline-block; /* Ensures it behaves like an inline element */
    text-align: center; /* Center text inside the button */
    transition: background-color 0.3s ease; /* Smooth transition on hover */
    margin-top: 2rem; /* Space above the button */
}

.carousel-button:hover {
    background-color: #814c20; /* Darker shade on hover */
}

/* Container to center the button */
.button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    margin: 0;
    margin-bottom: 3rem;
}

.headline-container {
    margin-top: 3rem;
}

/* Custom styling for Toastify */
.Toastify__toast--error {
    background-color: #814c20; /* Error red background */
    color: white;
    /*font-family: 'Dancing Script', cursive; /* Same font as headlines */
    font-size: 1.2rem; /* Adjust font size */
    padding: 1rem 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

/* Customize the close button style inside the toast */
.Toastify__close-button {
    color: white;
    font-size: 1.5rem;
}

/* Toast container positioning and transition */
.Toastify__toast-container {
    margin-bottom: 2rem; /* Add space from the bottom of the screen */
}

.ps-section {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #fecdd3;
}

.gift-code {
    background-color: #fdf2f2;
    padding: 1rem;
    margin: 0.5rem 0;
    border: 1px dashed #e11d48;
    border-radius: 0.25rem;
    text-align: center;
    font-family: monospace;
    font-size: 1.125rem;
    color: #e11d48;
}